<template>
  <div>
    <medicine-plan :TargetId="participant.UserId" />
  </div>
</template>
<script>
import MedicinePlan from "../../HealthModule/MedicinePlan.vue";
export default {
  components: { MedicinePlan },
  name: "ParticipantListMedicinePlan",
  props: {
    participant: Object,
  },
};
</script>